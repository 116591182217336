import Loader from './Loader'
interface ILoadingParams {
  bg?: boolean
}
export default function Loading({ bg = false }: ILoadingParams) {
  return (
    <div className={`${bg ? 'bg-gray-300' : 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80'} fixed inset-0 z-50 grid place-items-center`}>
      <Loader />
    </div>
  )
}