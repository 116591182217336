import { ReactNode, createContext, useContext } from 'react'

// Redux store
import { expireSession, reset } from 'store/store'
import { useDispatch } from 'react-redux'

// Custom hooks
import { useTypedSelector } from 'hooks'

// Components
import { SessionModal } from 'components'

// Types
import { IRootState } from 'types'

// Creating Context
const SessionContext = createContext<{ showSessionEnding: () => void } | undefined>(undefined)

export const useSession = () => {
  const context = useContext(SessionContext)
  if (!context) throw new Error('useImageViewer must be used within an SessionProvider')
  return context
}

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch()
  const { sessionIsExpired } = useTypedSelector((s: IRootState) => s.store)

  const showSessionEnding = () => dispatch(expireSession())
  const logOut = () => dispatch(reset())

  return (
    <SessionContext.Provider value={{ showSessionEnding }}>
      {children}
      {sessionIsExpired && <SessionModal logOut={logOut} />}
    </SessionContext.Provider>
  )
}