import myAxios from 'service/axios'

// Redux store
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { changeLoading, userAuth } from 'store/store'

export default async function checkTokenValidity(dispatch: Dispatch<AnyAction>, changeLoader: Function) {
  try {
    const response = await myAxios.get('/admins/userme')

    if (!(typeof response?.data === 'object' && response?.data?.status === 200 && (response?.data?.data?.user?.id || response?.data?.user?.id))) {
      dispatch(userAuth({ data: null, type: 'LOGOUT' }))
      return
    }

    dispatch(
      userAuth({
        data: { ...response?.data, access_token: response?.data?.access_token || localStorage?.getItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$') || '' },
        type: 'LOGIN',
      }),
    )
  } catch {
    dispatch(userAuth({ data: null, type: 'LOGOUT' }))
  } finally {
    changeLoader(false)
    dispatch(changeLoading(false))
  }
}