import { Suspense, useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'

// Redux store
import { useDispatch } from 'react-redux'
import { changeLoading } from 'store/store'

// Components
import { Loading, ScrollToTop } from 'components'

// Helpers
import { checkTokenValidity, handleOnline, switchTheme } from 'helpers'

// Custom hooks
import { useTypedSelector } from 'hooks'

// Routes list
import generateRoutes from './routesLinks'

// Types
import { IRootState } from 'types'

export default function Router(): JSX.Element {
  const params: URLSearchParams = new URLSearchParams(window?.location?.search)
  const dispatch = useDispatch()

  const {
    isLoading,
    user: { isAuth, rolesId },
  } = useTypedSelector((s: IRootState) => s.store)

  const [isOnline, setIsOnline] = useState<boolean>(true)
  const routes = useRoutes(generateRoutes(isAuth, rolesId || []))

  const [loading, setLoading] = useState<boolean>(true)

  const changeLoader = (param: boolean) => {
    setLoading(param)
  }

  async function checking() {
    if (!localStorage?.getItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$') && !params?.get('telegram_bot')) {
      dispatch(changeLoading(false))
      setLoading(false)
      return
    }

    await checkTokenValidity(dispatch, changeLoader)

    const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined'
    if (!isFromTelegramBot) {
      params?.delete('telegram_bot')
      window?.history?.pushState({}, '', `?${params?.toString()}`)
    }
  }

  const changeOnline = (param: boolean) => {
    setIsOnline(param)
  }

  // Device internet connection watcher
  useEffect(() => {
    handleOnline(true, isOnline, changeOnline)
    window?.addEventListener('online', () => handleOnline(false, isOnline, changeOnline))
    window?.addEventListener('offline', () => handleOnline(false, isOnline, changeOnline))

    return () => {
      window?.removeEventListener('online', () => handleOnline(false, isOnline, changeOnline))
      window?.removeEventListener('offline', () => handleOnline(false, isOnline, changeOnline))
    }
  }, [])

  // Checking user is admin on device internet connected
  useEffect(() => {
    if (!isOnline) return
    checking()
  }, [isOnline])

  useEffect(() => {
    switchTheme('get')
  }, [])
  
  return <Suspense fallback={<Loading />}>{!loading && routes}{isLoading && <Loading bg={!isAuth} />}<ScrollToTop /></Suspense>
}