import{default as Loader}from'./loader/Loader'
import{default as Loading}from'./loader/Loading'
import{default as SessionModal}from'./sessionModal/SessionModal'
import{default as SectionVisibilityDetector}from'./sectionVisibilityDetector/SectionVisibilityDetector'
import{lazy}from'react'
const Navbar=lazy(()=>import('./navbar/Navbar'))
const Roadbar=lazy(()=>import('./navbar/Roadbar'))
const Sidebar=lazy(()=>import('./sidebar/Sidebar'))
const PieChart=lazy(()=>import('./charts/PieChart'))
const AreaChart=lazy(()=>import('./charts/AreaChart'))
const TextSpan=lazy(()=>import('./textSpan/TextSpan'))
const FileInput=lazy(()=>import('./fileInput/FileInput'))
const CustomSelect=lazy(()=>import('./select/CustomSelect'))
const Pagination=lazy(()=>import('./pagination/Pagination'))
const AddItemButton=lazy(()=>import('./button/AddItemButton'))
const DraftEditor=lazy(()=>import('./draftEditor/DraftEditor'))
const Progressbar=lazy(()=>import('./progressbar/Progressbar'))
const AsyncSelect=lazy(()=>import('./asyncSelect/AsyncSelect'))
const DataFilters=lazy(()=>import('./dataFilters/DataFilters'))
const CountupCard=lazy(()=>import('./countupCard/CountupCard'))
const ScrollToTop=lazy(()=>import('./scrollToTop/ScrollToTop'))
const DeleteAlert=lazy(()=>import('./deleteAlerts/DeleteAlert'))
// const SessionModal=lazy(()=>import('./sessionModal/SessionModal'))
const ProgressText=lazy(()=>import('./progressText/ProgressText'))
const OrderIdInfo=lazy(()=>import('./orderComponents/OrderIdInfo'))
const OrderComment=lazy(()=>import('./orderComponents/OrderComment'))
const PasswordInput=lazy(()=>import('./passwordInput/PasswordInput'))
const ModalTextEditor=lazy(()=>import('./draftEditor/ModalTextEditor'))
const ImageUploadInput=lazy(()=>import('./fileInput/ImageUploadInput'))
const ThreeDotsModal=lazy(()=>import('./threeDotsModal/ThreeDotsModal'))
const CollapseLayout=lazy(()=>import('./orderComponents/CollapseLayout'))
// const SwipeableElement=lazy(()=>import('./swipeableElement/SwipeableElement'))
const OnlineUsersCount=lazy(()=>import('./onlineUsersCount/OnlineUsersCount'))
const ProductsAddTable=lazy(()=>import('./productsAddTable/ProductsAddTable'))
const OrderTopHeadBlock=lazy(()=>import('./orderComponents/OrderTopHeadBlock'))
const OrderInfoCollapse=lazy(()=>import('./orderComponents/OrderInfoCollapse'))
const UploadImageToOrder=lazy(()=>import('./uploadImageToOrder/UploadImageToOrder'))
const ComplateOrCancellation=lazy(()=>import('./orderComponents/ComplateOrCancellation'))
const CurrentOrderDetailView=lazy(()=>import('./orderComponents/CurrentOrderDetailView'))
export {
  Loader,
  Loading,
  Navbar,
  Roadbar,
  Sidebar,
  TextSpan,
  PieChart,
  AreaChart,
  FileInput,
  Pagination,
  DraftEditor,
  AsyncSelect,
  DataFilters,
  CountupCard,
  DeleteAlert,
  Progressbar,
  OrderIdInfo,
  ScrollToTop,
  ProgressText,
  CustomSelect,
  OrderComment,
  SessionModal,
  PasswordInput,
  AddItemButton,
  ThreeDotsModal,
  CollapseLayout,
  ModalTextEditor,
  OnlineUsersCount,
  ProductsAddTable,
  ImageUploadInput,
  // SwipeableElement,
  OrderInfoCollapse,
  OrderTopHeadBlock,
  UploadImageToOrder,
  ComplateOrCancellation,
  CurrentOrderDetailView,
  SectionVisibilityDetector,
}